import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { formlyConfig } from '@app/formly';
import { PbaEntryComponent } from '@app/pba/pba-entry.component';
import { ContactUsModalComponent } from '@app/root/components/contact-us-modal/contact-us-modal.component';
import { DocumentsComponent } from '@app/root/components/documents/documents.component';
import { FaqComponent } from '@app/root/components/faq/faq.component';
import { PrivacyComponent } from '@app/root/components/privacy/privacy.component';
import { AlmirallSupportComponent } from '@app/root/components/self-serve/support/alm/support.component';
import { HorizonSupportComponent } from '@app/root/components/self-serve/support/hzn/support.component';
import { UserInfoComponent } from '@app/root/components/self-serve/user-info/user-info.component';
import { SupportComponent } from '@app/root/components/support/support.component';
import { TcComponent } from '@app/root/components/tc/tc.component';
import { ToastsComponent } from '@app/root/components/toasts/toasts.component';
import { ToastService } from '@app/root/providers/toast-service';
import { AppRoutingModule } from '@app/routes.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import * as Sentry from '@sentry/angular-ivy';
import { AnalyticsService } from '@services/analytics.service';
import { AppStateService } from '@services/app-state.service';
import { AuthInterceptor } from '@services/auth.interceptor';
import { BlockedInsuranceService } from '@services/blocked-insurance.service';
import { BrightViewConfigService } from '@services/brightview-config.service';
import { CallService } from '@services/call.service';
import { CopayApiService } from '@services/copay-api.service';
import { CopayCodeRequestApiService } from '@services/copay-code-request-api.service';
import { DataViewConfigService } from '@services/dataview-config.service';
import { DateService } from '@services/date.service';
import { EtlImportService } from '@services/etl-import.service';
import { LogService } from '@services/log.service';
import { LookupsService } from '@services/lookups.service';
import { PharmacyApiService } from '@services/pharmacy-api.service';
import { PrescriberService } from '@services/prescriber.service';
import { ReasonService } from '@services/reason.service';
import { RetryInterceptor } from '@services/retry.interceptor';
import { RetryService } from '@services/retry.service';
import { ServiceLocator } from '@services/service-locator.service';
import { SurveillanceSettingsService } from '@services/surveillance-settings.service';
import { HttpTenantInterceptor } from '@services/tenant.interceptor';
import { TenantService } from '@services/tenant.service';
import { UrlService } from '@services/url.service';
import { UserStoreService } from '@services/user-store.service';
import { UserService } from '@services/user.service';
import { CaptchaComponent } from '@shared/components/captcha.component';
import { FormlyFieldListComponent } from '@shared/components/forms/formly-fields/formly-field-list/formly-field-list.component';
import { FormlyFieldRangeComponent } from '@shared/components/forms/formly-fields/formly-field-range.component';
import { NextEligibleDateWithReadyTextAndIconTableValuesStrategy } from '@shared/providers/data-transformation/next-eligible-date-with-ready-text-and-icon-table-values-strategy';
import { ParameterSelectionStrategies } from '@shared/providers/data-transformation/parameter-selection-strategies';
import { PassThroughStrategy } from '@shared/providers/data-transformation/pass-through-strategy';
import { PassThroughTableValueStrategy } from '@shared/providers/data-transformation/pass-through-table-value-strategy';
import { RequestCodesButtonWithDisableTableValueStrategy } from '@shared/providers/data-transformation/request-codes-button-with-disable-table-value-strategy';
import { StringArrayToCommaSeparatedStringStrategy } from '@shared/providers/data-transformation/string-array-to-comma-separated-string-strategy';
import { StringArrayToCommaSeparatedStringTableValueStrategy } from '@shared/providers/data-transformation/string-array-to-comma-separated-string-table-value-strategy';
import { SharedModule } from '@shared/shared.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    UserInfoComponent,
    HorizonSupportComponent,
    PrivacyComponent,
    DocumentsComponent,
    AlmirallSupportComponent,
    ToastsComponent,
    PbaEntryComponent,
    TcComponent,
    FaqComponent,
    SupportComponent,
    FormlyFieldListComponent,
    FormlyFieldRangeComponent,
    ContactUsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    MonacoEditorModule.forRoot(),
    NgxPaginationModule,
    AppRoutingModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot(formlyConfig),
    LeafletModule,
    NgbPopoverModule,
    CaptchaComponent,
  ],
  providers: [
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    PharmacyApiService,
    UserService,
    AnalyticsService,
    DateService,
    ReasonService,
    CopayCodeRequestApiService,
    UrlService,
    CallService,
    BlockedInsuranceService,
    PrescriberService,
    SurveillanceSettingsService,
    LogService,
    RetryService,
    EtlImportService,
    TenantService,
    CopayApiService,
    ParameterSelectionStrategies,
    PassThroughStrategy,
    PassThroughTableValueStrategy,
    StringArrayToCommaSeparatedStringStrategy,
    StringArrayToCommaSeparatedStringTableValueStrategy,
    NextEligibleDateWithReadyTextAndIconTableValuesStrategy,
    RequestCodesButtonWithDisableTableValueStrategy,
    ToastService,
    UserStoreService,
    AppStateService,
    LookupsService,
    CurrencyPipe,
    DecimalPipe,
    DataViewConfigService,
    BrightViewConfigService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = injector;
  }
}
